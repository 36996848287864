import {
  capitalize,
  filter,
  includes,
  join,
  lowerCase,
  map,
  split,
  startsWith,
  values,
} from 'lodash-es'

import { i18n } from 'src/i18n'
import { CountryCode } from 'src/types/address'
import { ProductCode } from 'src/types/product'

const getFormattedProductName = (code: ProductCode) => {
  const { t } = i18n.global

  return values(ProductCode).includes(code)
    ? t(`App.product.${code}`)
    : join(
        map(split(code, '_'), (v, i) => {
          if (i === 0) return capitalize(v)
          return lowerCase(v)
        }),
        ' '
      )
}

const isGermanProduct = (productCode: ProductCode) => {
  const germanProducts = filter(values(ProductCode), (code) =>
    startsWith(code, CountryCode.GERMANY)
  )

  return includes(germanProducts, productCode)
}

const isSciProduct = (code?: ProductCode) => {
  const sciProducts = [
    ProductCode.FR_LANDLORD_HOUSE,
    ProductCode.FR_LANDLORD_FLAT,
    ProductCode.FR_SECONDARY,
    ProductCode.FR_HOME,
    ProductCode.FR_MORTGAGE,
    ProductCode.FR_LANDLORD,
  ]

  return includes(sciProducts, code)
}

const isFrHomeProduct = (code?: ProductCode) => code === ProductCode.FR_HOME

const isFrLandlordProduct = (code?: ProductCode) =>
  code === ProductCode.FR_LANDLORD

const isFrLandlordFlatProduct = (code?: ProductCode) =>
  code === ProductCode.FR_LANDLORD_FLAT

const isFRHomeRepriseProduct = (
  code: ProductCode,
  externalOperation?: string
) => code === ProductCode.FR_HOME && externalOperation === 'novelia_suravenir'

const isMortgageProduct = (code?: ProductCode) =>
  code === ProductCode.FR_MORTGAGE

const isEscooterProduct = (code?: ProductCode) =>
  code === ProductCode.FR_ESCOOTER

const isRentProduct = (code?: ProductCode) => code === ProductCode.FR_RENT

const isInvertedRentProduct = (code?: ProductCode) =>
  code === ProductCode.FR_INVERTED_RENT

export {
  getFormattedProductName,
  isGermanProduct,
  isSciProduct,
  isMortgageProduct,
  isRentProduct,
  isInvertedRentProduct,
  isEscooterProduct,
  isFrHomeProduct,
  isFRHomeRepriseProduct,
  isFrLandlordFlatProduct,
  isFrLandlordProduct,
}
