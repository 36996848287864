import {
  filter,
  forEach,
  get,
  has,
  includes,
  isNil,
  isNumber,
  keys,
  reduce,
  set,
  unset,
} from 'lodash-es'
import { reactive } from 'vue'

import { storeProspect } from 'src/api/prospect'
import { isFRHomeRepriseProduct } from 'src/lib/product'
import { useProduct } from 'src/store/product'
import { BundleCode, OutbuildingSize } from 'src/types/enums'
import { ProductCode } from 'src/types/product'

const prospectData: Record<string, any> = reactive({})

const setProspectData = (path: string, data: any) => {
  if (path) {
    set(prospectData, path, data)

    if (path !== 'product') return

    const { setProduct } = useProduct()
    setProduct(data)

    return
  }
  keys(prospectData).forEach((key) => {
    if (has(data, key)) setProspectData(key, get(data, key))
    else deleteProspectData(key)
  })
  keys(data).forEach((key) => {
    if (has(prospectData, key)) return
    setProspectData(key, get(data, key))
  })
}

const getProspectData = (path: string = '') =>
  path === '' ? prospectData : get(prospectData, path)

const deleteProspectData = (path: string) => unset(prospectData, path)

const saveProspectData = async () => {
  const prospect = await storeProspect(prospectData)
  setProspectData('key', prospect.key)
}

const validateProspectData = () => {
  translateProductCode()
  removeInvalidUsers()
  removeInvalidValuables()
  addMissingOutbuildingBundles()
}

const translateProductCode = () => {
  const productCode = getProspectData('product')
  if (!productCode) return

  const {
    FR_HOME_FLAT,
    FR_HOME_HOUSE,
    FR_SECONDARY,
    FR_HOME,
    FR_LANDLORD,
    FR_LANDLORD_FLAT,
    FR_LANDLORD_HOUSE,
  } = ProductCode
  if (includes([FR_HOME_FLAT, FR_HOME_HOUSE, FR_SECONDARY], productCode))
    setProspectData('product', FR_HOME)
  if (includes([FR_LANDLORD_FLAT, FR_LANDLORD_HOUSE], productCode))
    setProspectData('product', FR_LANDLORD)
}

const removeInvalidValuables = () => {
  const valuableList = getProspectData('valuable.valuable')
  if (!valuableList || valuableList.length === 0) return

  const validatedList = [] as any[]
  let totalValuablesAmount = 0

  forEach(valuableList, (valuable) => {
    if (valuable?.type && isNumber(valuable?.value)) {
      validatedList.push(valuable)
      totalValuablesAmount += valuable.value
    }
  })

  if (totalValuablesAmount > 60000) {
    setProspectData('valuable.valuable', [])
    return
  }

  setProspectData('valuable.valuable', validatedList)
}

const removeInvalidUsers = () => {
  const userDetailsKeys = [
    'user.child',
    'user.roommate',
    'user.subscriber',
    'user.spouse',
  ]

  const validateUserWithEmail = (user: any) =>
    !!user.first_name && !!user.last_name && !!user.email

  const validateUserWithBirthdate = (user: any) =>
    !!user.first_name && !!user.last_name && !!user.birth_date

  forEach(userDetailsKeys, (key) => {
    if (key === 'user.child' && !!getProspectData(key)) {
      const childList = [...getProspectData(key)]

      const validatedList = reduce(
        childList,
        (acc: any[], cur) => {
          if (validateUserWithBirthdate(cur)) acc.push(cur)
          return acc
        },
        []
      )

      setProspectData(key, validatedList)
    }

    if (key === 'user.roommate' && !!getProspectData(key)) {
      const roommateList = [...getProspectData(key)]

      const validatedList = reduce(
        roommateList,
        (acc: any[], cur) => {
          if (validateUserWithEmail(cur)) acc.push(cur)
          return acc
        },
        []
      )

      setProspectData(key, validatedList)
    }

    if (
      key === 'user.spouse' &&
      !!getProspectData(key) &&
      !validateUserWithEmail(getProspectData(key))
    )
      deleteProspectData(key)

    const { product, other_info } = getProspectData()
    if (
      key === 'user.subscriber' &&
      !!getProspectData(key) &&
      !validateUserWithEmail(getProspectData(key)) &&
      !isFRHomeRepriseProduct(product, other_info?.external_operation)
    )
      deleteProspectData(key)
  })
}

const addMissingOutbuildingBundles = () => {
  const productName = getProspectData('product')
  const optionalBundles = getProspectData('optional_bundles') ?? []
  const packageCode = getProspectData('package')

  if (productName === ProductCode.FR_HOME && isNil(packageCode)) return

  const bundleMap = {
    [ProductCode.FR_HOME]: BundleCode.FR_HOME_OUTBUILDINGS,
    [ProductCode.FR_LANDLORD]: BundleCode.FR_LANDLORD_OUTBUILDINGS,
  }

  if (!includes(keys(bundleMap), productName)) return

  const outbuildingKeyMap = {
    [ProductCode.FR_HOME]: 'home.main_asset.outbuilding_size',
    [ProductCode.FR_LANDLORD]: 'home.main_asset.outbuilding_size',
  }

  const outbuildingValue = getProspectData(
    outbuildingKeyMap[productName as keyof typeof outbuildingKeyMap]
  )
  if (isNil(outbuildingValue) || outbuildingValue === OutbuildingSize.NONE) {
    setProspectData(
      'optional_bundles',
      filter(
        optionalBundles,
        (bundle) => bundle !== bundleMap[productName as keyof typeof bundleMap]
      )
    )
    return
  }

  if (
    includes(optionalBundles, bundleMap[productName as keyof typeof bundleMap])
  )
    return

  setProspectData('optional_bundles', [
    ...optionalBundles,
    bundleMap[productName as keyof typeof bundleMap],
  ])
}

const clearProductAssociatedValues = (product?: ProductCode) => {
  deleteProspectData('package')
  deleteProspectData('home.main_asset.content_value')
  deleteProspectData('home.main_asset.furnished_status')
  deleteProspectData('valuable')
  deleteProspectData('deductible')
  deleteProspectData('optional_bundles')
  deleteProspectData('outbuilding')
  deleteProspectData('home.main_asset.outbuilding_size')
  deleteProspectData('home.main_asset.outbuilding_flat_types')
  deleteProspectData('termination_reason')
  if (
    product === ProductCode.FR_LANDLORD_HOUSE ||
    product === ProductCode.FR_LANDLORD_FLAT ||
    product === ProductCode.FR_LANDLORD
  ) {
    deleteProspectData('user.child')
    deleteProspectData('user.spouse')
    deleteProspectData('user.roommate')
    deleteProspectData('user.other')
  }
}

export const useProspect = () => ({
  prospectData,
  setProspectData,
  getProspectData,
  saveProspectData,
  deleteProspectData,
  validateProspectData,
  clearProductAssociatedValues,
})
