import axios, { AxiosError } from 'axios'
import { get, includes, set, subtract } from 'lodash-es'

import { useApiController } from 'src/composables/useApiController'
import { useError } from 'src/composables/useError'
import { useNotification } from 'src/composables/useNotification'
import { router } from 'src/router'
import { useConfig } from 'src/store/config'
import { usePartner } from 'src/store/partner'
import { useUser } from 'src/store/user'

const { apiBaseUrl } = useConfig()

const Api = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
})

Api.interceptors.request.use(async (config) => {
  if (config.headers?.bearer) {
    const { currentKey, refreshCurrentKey } = usePartner()

    if (!currentKey.value) {
      await refreshCurrentKey()
    }
    delete config.headers.bearer
    config.headers.Authorization = `Bearer ${currentKey.value}`
  }

  if (config.params?.lang) {
    const { lang } = useUser()
    config.params.lang = lang.value
  }

  if (config.params?.abortSignal && config.params.requestName) {
    const { abortRequest, getAbortSignal } = useApiController()
    abortRequest(config.params.requestName)
    set(config, 'signal', getAbortSignal(config.params.requestName))
    delete config.params.abortSignal
    delete config.params.requestName
  }

  set(config, 'meta.startTime', window.performance.now())

  return config
})

Api.interceptors.response.use(
  (response) => {
    const endTime = window.performance.now()
    const startTime = get(response.config, 'meta.startTime')

    window.LukoTracking.trackEvent({
      id: 'API Call',
      properties: {
        api: {
          ...(startTime ? { response_time: subtract(endTime, startTime) } : {}),
          status_code: response.status,
          request_url: response.config.url,
          polling: includes(['get', 'GET'], response.config.method),
        },
      },
    })

    return response
  },
  (error: AxiosError) => {
    if (axios.isCancel(error)) return Promise.resolve({})

    const endTime = window.performance.now()
    const startTime = get(error.config, 'meta.startTime')

    const { parseError } = useError()
    const parsedError = parseError(error)

    window.LukoTracking.trackEvent({
      id: 'API Call',
      properties: {
        api: {
          ...(startTime ? { response_time: subtract(endTime, startTime) } : {}),
          status_code: parsedError.status,
          request_url: error.config.url,
          polling: includes(['get', 'GET'], error.config.method),
        },
      },
    })

    if (parsedError.redirectTo) router.push({ name: parsedError.redirectTo })
    if (parsedError.action) parsedError.action()

    const { showNotification } = useNotification()
    showNotification({ message: parsedError.message, theme: 'danger' })

    throw error
  }
)

export { Api }
