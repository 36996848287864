import { Api } from 'src/api'
import { useLocalStorage } from 'src/lib/localStorage'
import { User } from 'src/types/user'

import { LoginParams, SignupParams } from './types'

const loginAPI = async (data: LoginParams) => {
  await Api.post('/v1/auth/login', data)

  const { setStorageItem } = useLocalStorage()
  setStorageItem('isLogged', 'true')
}

const logoutAPI = () => Api.post('/v1/auth/logout')

const ssoLoginAPI = async (token: string) => {
  await Api.get(`/v1/auth/sso/login?token=${token}`)

  const { setStorageItem } = useLocalStorage()
  setStorageItem('isLogged', 'true')
}

const signup = (data: SignupParams) =>
  Api.post(`/v1/user/invitation`, data, { params: { lang: true } })

const changePassword = (email: User['email']) =>
  Api.post('/v1/user/password/send_reset_email', { email })

const resetPassword = (params: { password: string; token: string }) =>
  Api.post('/v1/user/password/reset', params)

export {
  loginAPI,
  ssoLoginAPI,
  logoutAPI,
  changePassword,
  resetPassword,
  signup,
}
