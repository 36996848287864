import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const quoteRoutes: RouteRecordRaw[] = [
  {
    path: '/new-quote/:key?',
    name: 'newQuote',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const newQuoteModule = await import('../NewQuote.vue')
        return newQuoteModule
      } finally {
        await finishProgress()
      }
    },
    meta: { titleKey: 'NewQuote.metaTitle' },
  },
]
