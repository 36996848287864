import { forEach, keys, set, unset } from 'lodash-es'
import { reactive } from 'vue'

import { ContractState } from 'src/modules/ContractDetails/types'
import { OccupantType, Package } from 'src/modules/Dashboard/types'
import { Channel, ConversionChannel } from 'src/types/enums'
import { PartnerIndustry, PartnerType } from 'src/types/partner'
import { ProductCode } from 'src/types/product'
import { ProspectStatus } from 'src/types/prospect'
import { TenantFileStatus } from 'src/types/tenantFile'

interface ConsumersFilters {
  textSearch?: string
  page: number
  status?: string
  contracts?: number
}

interface ContractsFilters {
  textSearch?: string
  page: number
  state?: ContractState
  code?: ProductCode
  channels?: Channel[]
  conversionChannels?: ConversionChannel[]
  sortField?: string
  sortAsc?: boolean
  timePeriod: string
}

interface PartnersFilters {
  textSearch?: string
  page: number
  role?: PartnerType
  industry?: PartnerIndustry
  withOrias?: boolean
}

interface ProspectsFilters {
  search?: string
  page: number
  type?: ProductCode | 'FR_HOME_REPRISE'
  date_from?: string
  status?: ProspectStatus
}

interface DashboardFilters {
  salesIds?: number[]
  products?: string[]
  channels?: Channel[]
  conversionChannels?: ConversionChannel[]
  occupantTypes?: OccupantType[]
  packages?: Package[]
  timePeriod: string
}

interface TenantFilesFilters {
  textSearch?: string
  statusType?: TenantFileStatus
  createdSince?: string
  agent?: string
}

interface CouponFilters {
  textSearch?: string
  page: number
  validityFcts?: string
  acquisitionChannel?: string
}

type FilteringPossibilites = ConsumersFilters &
  ContractsFilters &
  PartnersFilters &
  ProspectsFilters &
  DashboardFilters &
  TenantFilesFilters &
  CouponFilters

const filtersConfig: FilteringPossibilites = {
  textSearch: '',
  page: 1,
  timePeriod: '',
}

const filters = reactive({ ...filtersConfig })

const changeFilter = (
  name: keyof FilteringPossibilites,
  value?: string | number | boolean | Array<string | number>
) => {
  set(filters, name, value)
}

const clearFilters = () => {
  forEach(keys(filters), (key) => unset(filters, key))
  forEach(keys(filtersConfig), (key) =>
    set(filters, key, filtersConfig[key as keyof FilteringPossibilites])
  )
}

export const useFilters = () => ({
  filters,
  changeFilter,
  clearFilters,
})
