import { replace } from 'lodash-es'
import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'

export const prospectsRoutes: RouteRecordRaw[] = [
  {
    path: '/prospects',
    name: 'prospects',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const prospectsModule = await import('../Prospects.vue')
        return prospectsModule
      } finally {
        await finishProgress()
      }
    },
    meta: {
      titleKey: 'Prospects.metaTitle',
    },
    beforeEnter: (to, _, next) => {
      if (to.query.status) {
        to.meta['status'] = to.query.status
        delete to.query.status
        to.fullPath = replace(to.fullPath, /\?status=.*/, '')
      }

      next()
    },
  },
]
