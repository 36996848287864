import { Check } from '@demain-es/lukompo'
import { ref, watch, WatchStopHandle } from 'vue'

import { i18n } from 'src/i18n'

const { t } = i18n.global

interface ConfirmationPopinOptions {
  title: string
  description?: string
  checkboxLabel?: string
  icon?: (color: string) => string
  theme?: string
  ctaValid?: string
  ctaCancel?: string
  dataLukoTracking?: string
  action?: Function
  hasColumnCta?: boolean
  info?: string
}

const confirmationPopinOptions = ref<ConfirmationPopinOptions>({
  title: '',
  icon: Check,
  ctaValid: t('App.cta.confirm'),
  ctaCancel: t('App.cta.cancel'),
  checkboxLabel: '',
})

const isConfirmationPopinDisplayed = ref(false)
const isLoading = ref(false)
const resolvePopin = ref<boolean | null>(null)

let unwatch: WatchStopHandle

const showConfirmationPopin = (
  options: ConfirmationPopinOptions
): Promise<boolean> =>
  new Promise((resolve) => {
    Object.assign(confirmationPopinOptions.value, options)
    isConfirmationPopinDisplayed.value = true

    unwatch = watch(resolvePopin, async (val) => {
      if (val === null) return

      isConfirmationPopinDisplayed.value = false
      resolve(val)
      unwatch()
    })

    if (isConfirmationPopinDisplayed.value !== null) {
      resolvePopin.value = null
    }
  })

const refreshOptions = () => {
  confirmationPopinOptions.value = {
    title: '',
    icon: Check,
    ctaValid: t('App.cta.confirm'),
    ctaCancel: t('App.cta.cancel'),
    checkboxLabel: '',
  }
}

export const useConfirmationPopin = () => ({
  isConfirmationPopinDisplayed,
  confirmationPopinOptions,
  showConfirmationPopin,
  resolvePopin,
  refreshOptions,
  isLoading,
})
