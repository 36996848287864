import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { sleep } from 'src/lib/webUtils'
import { usePartner } from 'src/store/partner'

export const tenantFilesRoutes: RouteRecordRaw[] = [
  {
    path: '/tenant-files',
    component: async () => {
      const { initiateProgress, finishProgress } = useDownloadProgress()
      try {
        initiateProgress()
        const TenantFilesModule = await import('../TenantFiles.vue')
        return TenantFilesModule
      } finally {
        await finishProgress()
      }
    },
    name: 'tenantFiles',
    meta: {
      titleKey: 'TenantFiles.title',
    },
    beforeEnter: async () => {
      const { currentPartner, isRealEstatePartner } = usePartner()

      while (!currentPartner.value) {
        await sleep(100)
      }

      if (!isRealEstatePartner.value) return

      return { name: 'sunset' }
    },
  },
]
