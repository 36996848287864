import { RouteRecordRaw } from 'vue-router'

import { useDownloadProgress } from 'src/composables/useDownloadProgress'
import { sleep } from 'src/lib/webUtils'
import { usePartner } from 'src/store/partner'

export const tenantFileDetailsRoutes: RouteRecordRaw[] = [
  {
    path: '/tenant-file/:tenantFileUuid',
    component: () => import('../TenantFileDetails.vue'),
    children: [
      {
        path: '',
        name: 'tenantFileDetails',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const tenantFileOverview = await import(
              '../components/TenantFileOverview/TenantFileOverview.vue'
            )
            return tenantFileOverview
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'TenantFileDetails.title',
        },
      },
      {
        path: 'documents',
        name: 'tenantFileDocuments',
        component: async () => {
          const { initiateProgress, finishProgress } = useDownloadProgress()
          try {
            initiateProgress()
            const tenantFileDocuments = await import(
              '../components/TenantFileDocuments/TenantFileDocuments.vue'
            )
            return tenantFileDocuments
          } finally {
            await finishProgress()
          }
        },
        meta: {
          titleKey: 'TenantFileDocuments.metaTitle',
        },
      },
    ],
    beforeEnter: async () => {
      const { currentPartner, isRealEstatePartner } = usePartner()

      while (!currentPartner.value) {
        await sleep(100)
      }

      if (!isRealEstatePartner.value) return

      return { name: 'sunset' }
    },
  },
]
