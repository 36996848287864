import { Api } from 'src/api'
import { getSerializedParams } from 'src/lib/webUtils'
import { ApiRequestName } from 'src/types/api'

import {
  ContentValuesResponse,
  ContractCreatedResponse,
  ContractCreationPaymentParams,
  ContractResponse,
  ContractsQueryParams,
  ContractsResponse,
  ContractTenantFilesResponse,
  ContractTerminationInfosResponse,
  ContractTerminationOptions,
  ContractTerminationReasonsResponse,
  ContractVersionsResponse,
} from './types'

const getContracts = async (params: ContractsQueryParams) => {
  const { data } = await Api.get<ContractsResponse>(`/v1/partner/contracts`, {
    params: {
      ...params,
      lang: true,
      abortSignal: true,
      requestName: ApiRequestName.GET_CONTRACTS,
    },
    headers: { bearer: true },
    // can be replaced with { indexes: null } in the newer version of axios
    paramsSerializer: getSerializedParams,
  })
  return data
}

const getContract = async (contractUuid: string) => {
  const { data } = await Api.get<ContractResponse>(
    `/v1/partner/contract/${contractUuid}`,
    { params: { lang: true }, headers: { bearer: true } }
  )

  return data
}

const getContractTenantFiles = async (contractUuid: string) => {
  const { data } = await Api.get<ContractTenantFilesResponse>(
    `/v1/partner/contracts/${contractUuid}/flow_summaries`,
    { headers: { bearer: true } }
  )

  return data
}

const getContractVersions = async (contractUuid: string) => {
  const { data } = await Api.get<ContractVersionsResponse>(
    `/v1/partner/contract/${contractUuid}/contract_versions`,
    {
      params: {
        lang: true,
        abortSignal: true,
        requestName: ApiRequestName.GET_CONTRACT_VERSIONS,
      },
      headers: { bearer: true },
    }
  )

  return data
}

const remindContract = async (contractUuid: string) =>
  Api.post(
    `/v1/partner/contract/${contractUuid}/reminder`,
    { type: 'email' },
    {
      headers: { bearer: true },
    }
  )

const addDiscount = async (
  contractUuid: string,
  discountPayload: {
    discount_amount: number
    reimbursment_type: string
    reason: string
  }
) =>
  Api.post(`/v1/partner/contract/${contractUuid}/discount`, discountPayload, {
    headers: { bearer: true },
  })

const getContentValues = async (homeUuid: string) => {
  const { data } = await Api.get<ContentValuesResponse>(
    `/v1/homes/${homeUuid}/content_values`,
    {
      headers: { bearer: true },
    }
  )
  return data
}

const getContractTerminationReasons = async (contractUuid: string) => {
  const { data } = await Api.get<ContractTerminationReasonsResponse>(
    `/v1/partners/contracts/${contractUuid}/lukoff_intent`,
    {
      params: { lang: true },
      headers: { bearer: true },
    }
  )

  return data
}

const getContractTerminationInfos = async (contractUuid: string) => {
  const { data } = await Api.get<ContractTerminationInfosResponse>(
    `/v1/partner/contract/${contractUuid}/lukoff`,
    {
      params: { lang: true },
      headers: { bearer: true },
    }
  )

  return data
}

const terminateContract = async (
  contractUuid: string,
  terminationOptions: ContractTerminationOptions
) => {
  const { data } = await Api.post<ContractTerminationReasonsResponse>(
    `/v1/partner/contract/${contractUuid}/lukoff`,
    terminationOptions,
    {
      headers: { bearer: true },
    }
  )

  return data
}
const editTermination = async (contractUuid: string, lukoffDate: string) => {
  const { data } = await Api.patch<ContractTerminationReasonsResponse>(
    `/v1/partner/contract/${contractUuid}/lukoff/date`,
    { lukoff_date: lukoffDate },
    {
      headers: { bearer: true },
    }
  )

  return data
}

const cancelTermination = async (contractUuid: string) =>
  Api.delete(`/v1/partner/contract/${contractUuid}/lukoff`, {
    headers: { bearer: true },
  })

const createContract = async (
  prospectKey: string,
  paymentParams: ContractCreationPaymentParams
) => {
  const { data } = await Api.post<ContractCreatedResponse>(
    `/v1/prospect/${prospectKey}/contract`,
    paymentParams,
    { params: { lang: true }, headers: { bearer: true } }
  )
  return data
}

const updatePartner = async (contractUuid: string, partnerId: number) =>
  Api.patch(
    `v1/contracts/${contractUuid}/partner_id`,
    { partner_id: partnerId },
    { headers: { bearer: true } }
  )

const switchBillingFrequencyToYearly = async (
  contractUuid: string,
  consumerUuid: string
) =>
  await Api.post(
    `v1/partner/contracts/${contractUuid}/consumers/${consumerUuid}/switch_to_yearly`,
    {},
    { headers: { bearer: true } }
  )

const sendTransferEligibilityEmail = async () => {
  /*  Api.post(`/v1/partner/contract/${contractUuid}/termination/reminder`, {
    headers: { bearer: true },
  }) */
}

export {
  addDiscount,
  getContracts,
  getContract,
  getContractTenantFiles,
  getContractVersions,
  remindContract,
  getContentValues,
  getContractTerminationReasons,
  getContractTerminationInfos,
  terminateContract,
  cancelTermination,
  editTermination,
  createContract,
  updatePartner,
  switchBillingFrequencyToYearly,
  sendTransferEligibilityEmail,
}
