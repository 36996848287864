<template>
  <component
    :is="component"
    :data-luko-tracking="item.dataLukoTracking ?? 'NavListItem'"
    :class="[
      $style.listItem,
      isSelected && $style.selected,
      (item.isComing || item.isDisabled) && $style.disabled,
    ]"
    v-bind="linkAttribute"
    @click="item.onClick"
  >
    <div :class="$style.label">
      <LukoIcon
        :color="itemIconColor"
        :svg="item.icon"
        :class="$style.icon"
        :width="16"
        :height="16"
      />
      {{ item.text }}
      <div v-if="item.isComing" :class="$style.coming">
        {{ t('Navbar.coming') }}
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import { LukoIcon } from '@demain-es/lukompo'
import { map } from 'lodash-es'
import { computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import { useTheme } from 'src/composables/useTheme'
import { consumerDetailsRoutes } from 'src/modules/ConsumerDetails/routes'
import { NavItem } from 'src/modules/Navbar/types'
import { partnerDetailsRoutes } from 'src/modules/PartnerDetails/routes'
import { usePartner } from 'src/store/partner'

const props = defineProps({
  item: { type: Object as PropType<NavItem>, required: true },
})

const component = computed(() => {
  if (props.item.url) return 'a'

  if (props.item.routeName && !props.item.isComing && !props.item.isDisabled)
    return 'router-link'

  return 'div'
})

const linkAttribute = computed(() => {
  if (props.item.url) return { href: props.item.url, target: '_blank' }

  if (props.item.routeName && !props.item.isComing && !props.item.isDisabled)
    return { to: { name: props.item.routeName, force: true } }

  return {}
})

const itemIconColor = computed(() => {
  if (props.item.isComing || props.item.isDisabled) return 'gray500'
  if (isSelected.value) return ''
  return 'gray1000'
})

const route = useRoute()
const isSelected = computed(() => {
  if (!route.name) return false

  const consumerRoutes = map(
    consumerDetailsRoutes[0].children,
    (child) => child.name
  )
  const partnerRoutes = map(
    partnerDetailsRoutes[0].children,
    (child) => child.name
  )

  const { isLukoPartner } = usePartner()

  if (
    (route.name === 'contractDetails' &&
      props.item.routeName === 'contracts') ||
    (consumerRoutes.includes(route.name) &&
      props.item.routeName === 'consumers') ||
    (route.name === 'tenantFileDetails' &&
      props.item.routeName === 'tenantFiles') ||
    (partnerRoutes.includes(route.name) &&
      props.item.routeName === 'partners') ||
    (route.name === 'tenantFileDetails' &&
      isLukoPartner.value &&
      props.item.routeName === 'contracts') ||
    (route.name === 'newQuote' && props.item.routeName === 'prospects') ||
    (route.name === 'newCoupon' && props.item.routeName === 'coupons')
  )
    return true

  return route.name === props.item.routeName
})

const { t } = useI18n()
const { currentTheme } = useTheme()
</script>

<style lang="scss" module>
.listItem {
  @include typo-sub-text;
  display: flex;
  align-items: center;
  padding: 8px 16px;

  cursor: pointer;
}

.selected {
  @include typo-sub-text-bold;
  color: v-bind('currentTheme.backgroundColor');
}

.disabled {
  color: $gray-500;

  pointer-events: none;
}

.icon {
  transform: translateY(-1px);
}

.coming {
  @include typo-sub-text-bold;
  padding: 0 4px;
  border-radius: 4px;

  color: $gray-50;
  font-size: 10px;

  text-transform: uppercase;

  background-color: $gray-300;
}

.label {
  display: flex;
  gap: 12px;
  align-items: center;
}
</style>
