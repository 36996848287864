<template>
  <div :key="locale">
    <!-- <NpsBanner
      v-if="shouldShowBanner"
      v-model:is-displayed="isNpsBannerDisplayed"
    /> -->
    <div
      :class="[
        $style.root,
        shouldShowBanner && isNpsBannerDisplayed && $style.isSpaced,
      ]"
      data-luko-tracking="Companion"
    >
      <AppLoader v-if="isLoaderActive" />
      <template v-else>
        <LukoBanner
          :show="isNotificationDisplayed"
          :icon="notificationOptions?.icon"
          :message="notificationOptions?.message"
          :theme="notificationOptions?.theme"
          data-test="Notification-Bar"
          @update:show="hideNotification"
        />
        <DownloadProgressBar />
        <ConfirmationPopin v-if="isConfirmationPopinDisplayed" />
        <EmbeddedTypeform
          v-if="isTypeformDisplayed"
          v-model="isTypeformDisplayed"
        />
        <Navbar
          v-if="showNavbar"
          :class="shouldShowBanner && isNpsBannerDisplayed && $style.isSpaced"
        />
        <main :class="$style.main">
          <router-view v-slot="{ Component }">
            <component :is="Component" />
          </router-view>
        </main>
        <iframe
          v-if="noveliaIadvizeUrl"
          :src="noveliaIadvizeUrl"
          title="Iadvize chat"
          sandbox="allow-same-origin allow-scripts"
          :class="$style.iadvize"
        ></iframe>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LukoBanner, Reload } from '@demain-es/lukompo'
import { useHead } from '@vueuse/head'
import { computed, defineAsyncComponent, onMounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'

import { AppLoader } from 'src/components/AppLoader'
import { ConfirmationPopin } from 'src/components/ConfirmationPopin'
// import { NpsBanner } from 'src/components/NpsBanner'
import { DownloadProgressBar } from 'src/components/DownloadProgressBar'
import { useConfirmationPopin } from 'src/composables/useConfirmationPopin'
import { useEmbeddedTypeform } from 'src/composables/useEmbeddedTypeform'
import { useLoader } from 'src/composables/useLoader'
import { useNotification } from 'src/composables/useNotification'
import { useNpsBanner } from 'src/composables/useNpsBanner'
import { useLocalStorage } from 'src/lib/localStorage'
import { getUrlString } from 'src/lib/webUtils'
import { Navbar } from 'src/modules/Navbar'
import { useConfig } from 'src/store/config'
import { usePartner } from 'src/store/partner'
import { useUser } from 'src/store/user'
import { useWorkspace } from 'src/store/workspace'
import { WorkspaceId } from 'src/types/workspace'
const EmbeddedTypeform = defineAsyncComponent(
  () => import('src/components/EmbeddedTypeform')
)

const { t, locale } = useI18n()
const { isLogged, refreshUser } = useUser()
const { currentKey, currentPartner } = usePartner()
const { isLoaderActive, showLoader, hideLoader } = useLoader()
const { isNoveliaWs, isWorkspaceContext } = useWorkspace()
const { noveliaIadvizeBaseUrl } = useConfig()
const { isTypeformDisplayed } = useEmbeddedTypeform()

const route = useRoute()

const isPlain = computed(() => !!route.meta.isPlain)

const showNavbar = computed(
  () => !!(isLogged.value && !isPlain.value && currentKey.value)
)

const noveliaIadvizeUrl = computed(() => {
  if (!isNoveliaWs || isWorkspaceContext.value || !noveliaIadvizeBaseUrl.value)
    return ''

  const { broker_code, email_address, broker_id, company_name, postal_code } =
    currentPartner.value?.broker ?? {}

  if (!broker_code || !email_address || !broker_id) return ''

  return getUrlString({
    baseUrl: noveliaIadvizeBaseUrl.value,
    query: {
      pcode: broker_code,
      puser: broker_id,
      ...(company_name && { pnom: company_name }),
      pemail: email_address,
      ...(postal_code && { pcp: postal_code }),
    },
  })
})

const { isNotificationDisplayed, notificationOptions, hideNotification } =
  useNotification()

const { showConfirmationPopin, isConfirmationPopinDisplayed } =
  useConfirmationPopin()

onMounted(async () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.addEventListener('message', async (event) => {
      if (event.data.message !== 'appUpdated') return

      const choice = await showConfirmationPopin({
        icon: Reload,
        title: t('NewVersionPopin.title'),
        description: t('NewVersionPopin.description'),
        ctaValid: t('NewVersionPopin.cta.refresh'),
      })

      if (!choice) return

      location.reload()
    })
  }

  try {
    showLoader()
    const { getStorageItem } = useLocalStorage()
    if (!getStorageItem('isLogged')) return
    await refreshUser()
  } finally {
    hideLoader()
  }
})

useHead({
  title: computed(() => {
    if (route.meta?.titleKey) return t(`${route.meta.titleKey}`)
    if (isNoveliaWs.value) return 'Novelia Habitat Luko'
    return 'Companion'
  }),
  link: computed(() => {
    let href = '/favicon.png'
    if (isWorkspaceContext.value && isNoveliaWs.value)
      href = '/favicon-novelia.png'
    if (
      !isWorkspaceContext.value &&
      currentPartner.value?.workspace_id === WorkspaceId.NOVELIA
    )
      href = '/favicon-novelia.png'

    return [{ rel: 'icon', href }]
  }),
})

const { replace } = useRouter()
watch(currentKey, () => {
  const { name, params, query } = route

  if (!name) return

  replace({ name, params, query, force: true })
})

const { isNpsBannerDisplayed } = useNpsBanner()
const shouldShowBanner = false
</script>

<style lang="scss" module>
.root {
  display: flex;
  gap: 2px;
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  overflow: auto;

  background: $app-background;
}

.isSpaced {
  min-height: calc(100vh - 36px);
  max-height: calc(100vh - 36px);
}

.main {
  flex: 1;
}
.iadvize {
  position: fixed;
  right: 8px;
  bottom: 8px;

  height: 350px;
}
</style>
