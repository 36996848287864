import { Api } from 'src/api'
import { Language } from 'src/types/user'

import { AvatarUploadResponse, UserResponse } from './types'

const getUser = async () => {
  const { data } = await Api.get<UserResponse>('/v1/user')
  return data
}

const uploadAvatarAPI = (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  return Api.post<AvatarUploadResponse>('/v1/user/avatar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const updateUserLanguage = (lang: Language) =>
  Api.post('/v1/user/lang', {
    lang: lang,
  })

const updatePassword = (oldPassword: string, newPassword: string) =>
  Api.post('/v1/user/password/change', {
    old_password: oldPassword,
    new_password: newPassword,
  })

export { getUser, uploadAvatarAPI, updateUserLanguage, updatePassword }
